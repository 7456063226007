import * as React from "react";
import axios from "axios";
import {Game} from "../types";
import TimelineSlider from "./TimelineSlider";
import debounce from "lodash.debounce";
import UploadEditForm from "./UploadEditForm/index";

interface SubmitProps {
    title: string;
    description?: string;
    isPrivate: boolean;
    isSpoiler: boolean;
    selectedGame: Game | null;
}

const HalfColumn = ({children}: React.PropsWithChildren) => (
    <div className="flex w-[80%] md:h-full md:w-[50%] md:px-3 md:py-12">
        <div className="flex w-full items-center justify-center md:h-full">
            {children}
        </div>
    </div>
);

export default function Index() {
    const formRef = React.useRef<any>();
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const [isUploading, setIsUploading] = React.useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = React.useState<number>(0);
    const [selectedFile, setSelectedFile] = React.useState<File>();
    const [videoLength, setVideoLength] = React.useState<number>(0);

    const [{startTime, endTime}, setTrimTime] = React.useState({
        startTime: 0,
        endTime: videoLength,
    });
    const [error, setError] = React.useState<{
        messages: string[];
        header: string;
    }>();

    const videoToUpload =
        (selectedFile && URL.createObjectURL(selectedFile)) || "";

    const changeHandler = React.useCallback(
        debounce(({min, max}: { min: number; max: number }) => {
            setTrimTime({startTime: min, endTime: max});
        }, 500),
        []
    );

    const handleLoadedMetadata = () => {
        const video = videoRef.current;
        if (!video) return;
        setVideoLength(parseInt(`${video.duration}`));
    };

    const renderVideo = React.useMemo(
        () => (
            <HalfColumn>
                <div className="flex-0 flex flex-col gap-2 p-4 md:flex-1 md:pr-[10%]">
                    <video
                        ref={videoRef}
                        src={`${videoToUpload}#t=${startTime},${endTime}`}
                        preload="auto"
                        controls={!isUploading}
                        onLoadedMetadata={handleLoadedMetadata}
                    />
                    {videoLength > 0 && !isUploading && (
                        <TimelineSlider
                            min={0}
                            max={videoLength}
                            onChange={changeHandler}
                        />
                    )}
                </div>
            </HalfColumn>
        ),
        [selectedFile, videoLength, startTime, endTime, isUploading]
    );

    if (!selectedFile) {
        return (
            <>
                <div>
                    <HalfColumn>
                        <div className="flex flex-0 flex-col gap-2 p-4 md:flex-1 md:pr-[10%]">

                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                   htmlFor="file">Upload
                                file</label>
                            <input
                                className="block w-full text-sm text-gray-900 border border-gray-300 py-1.5 rounded-lg cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                                  file:mr-4 file:py-2 file:px-4
                                  file:rounded-lg file:border-0
                                  file:text-sm file:font-semibold
                                  file:bg-gray-800 file:text-gray-400 file:border-gray-600
                                  hover:file:bg-gray-800
                                  hover:file:text-white"
                                accept="video/*"
                                onChange={({target}) => {
                                    const file = target.files?.[0];
                                    if (!file) return;
                                    setSelectedFile(file);
                                }}
                                type="file" required={true}/>
                        </div>
                    </HalfColumn>
                </div>
            </>
        );
    }

    // @ts-ignore
    const handleOnSubmit = async ({
                                      title,
                                      description,
                                      isPrivate,
                                      isSpoiler,
                                      selectedGame,
                                  }: SubmitProps) => {
        if (!title.length) {
            const errors = ["A title is required to upload a video."];
            setError({
                header: "The following errors occured while uploading your video:",
                messages: errors,
            });
        } else {
            const formData = new FormData();

            formData.append("file", selectedFile);
            formData.append("title", title);
            formData.append("is_private", isPrivate ? "1" : "0");
            formData.append("is_spoiler", isSpoiler ? "1" : "0");

            formData.append("start_time", startTime.toString());

            if (endTime < videoLength) {
                formData.append("end_time", endTime.toString());
            } else {
                formData.append("end_time", videoLength.toString());
            }

            if(description) {
                formData.append("description", description);
            }
            if (selectedGame) {
                formData.append("igdb_id", selectedGame.igdb_id.toString());
            }

            try {
                setIsUploading(true);
                const {
                    data
                } = await axios(`/upload`, {
                    data: formData,
                    method: "POST",
                    withCredentials: true,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept-Encoding": "application/json",
                    },
                    onUploadProgress: ({loaded, total}) => {
                        if (total) {
                            setUploadProgress(Math.round((loaded / total) * 100));
                        }
                    },
                });
                window.location.href = '/feed';
            } catch(ex) {
                console.error(ex)
            }
        }
    };

    if (selectedFile) {
        return (
            <div className="flex flex-row justify-between">
                <div className="w-96 flex flex-col gap-8">
                    <UploadEditForm formDisabled={false} ref={formRef}/>

                    {isUploading ? (
                        <button
                            disabled
                            className="flex w-full cursor-not-allowed justify-center rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-sm font-medium text-white opacity-50"
                        >
                            {isUploading
                                ? `Uploading ${uploadProgress}%...`
                                : "Upload"}
                        </button>
                    ) : (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                setError(undefined);
                                const {...formData} =
                                    formRef.current?.handleFormData();
                                console.log(formData)
                                handleOnSubmit(formData);
                            }}
                            type="submit"
                            className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            Upload
                        </button>
                    )}
                </div>
                {renderVideo}
            </div>
        );
    }
}
