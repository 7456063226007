import * as React from "react";
import {Game} from "../../types";
import GameSelect from "../GameSelect";

export default function Index() {
    const setSelectedGame = (game: Game | null) => {
        if (!!game) {
            window.location.href = '/feed/' + game.igdb_id;
        }
    };
    return (
        <GameSelect
            showLabel={false}
            onGameSelect={setSelectedGame}
            selectedGame={null}
        />);
}