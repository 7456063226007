import { createRoot } from 'react-dom/client';
import UploadForm from './components/uploadForm';
import SidebarSearch from './components/SidebarSearch';

const sidebarGameSearchRoot = document.getElementById("react-sidebarSearch");

if (sidebarGameSearchRoot) {
    const sidebarSearchRoot = createRoot(sidebarGameSearchRoot);
    sidebarSearchRoot.render(<SidebarSearch />);
}

const videoRoot = document.getElementById('react-video');
if (videoRoot) {
    const videoReactRoot = createRoot(videoRoot);
    videoReactRoot.render(<UploadForm />);
}