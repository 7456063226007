import * as React from "react";
import GameSelect from "../GameSelect";
import {Game} from "../../types";

interface Props {
    media?: Media;
    formDisabled: boolean;
}

type Media = {
    title: string;
    description: string;
    is_private: boolean;
    is_spoiler: boolean;

}

const UploadEditForm = React.forwardRef(
    ({formDisabled, media}: Props, ref) => {
        const [selectedGame, setSelectedGame] = React.useState<Game | null>(
          null
        );
        const [title, setTitle] = React.useState<string>(media?.title || "");
        const [description, setDescription] = React.useState<string>(
            media?.description || ""
        );
        const [isPrivate, setIsPrivate] = React.useState<boolean>(
            media?.is_private || false
        );
        const [isSpoiler, setIsSpoiler] = React.useState<boolean>(
            media?.is_spoiler || false
        );

        // readd game
        const handleFormData = React.useCallback(() => {
            return {title, description, isPrivate, isSpoiler, selectedGame};
        }, [title, description, isPrivate, isSpoiler, selectedGame]);

        React.useImperativeHandle(
            ref,
            () => ({
                handleFormData,
            }),
            [handleFormData]
        );

        return (
            <>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">Title</label>
                    <div className="mt-2">
                        <input type="text" name="title" id="title"
                               value={title}
                               disabled={formDisabled}
                               onChange={({target}) => setTitle(target.value)}
                               className="block w-full rounded-md border-0 py-1.5 bg-gray-600 text-gray-400 shadow-sm ring-1 ring-inset ring-slate-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                               placeholder="My fancy clip"
                               required
                        />
                    </div>
                </div>
                <div>
                    <div className="flex justify-between flex-row">
                        <label htmlFor="description"
                               className="block text-sm font-medium leading-6 text-white">Description</label>
                        <span className="text-sm leading-6 text-gray-500" id="description-optional">Optional</span>
                    </div>
                    <div className="mt-2">
                    <textarea name="description" id="description"
                              className="block w-full rounded-md border-0 py-1.5 bg-gray-600 text-gray-400 shadow-sm ring-1 ring-inset ring-slate-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="In this clip, Brent does something really dumb"
                              aria-describedby="description-optional"
                              value={description}
                              disabled={formDisabled}
                              onChange={({target}) => setDescription(target.value)}
                              rows={4}
                    ></textarea>
                    </div>
                </div>


                <div>
                    <div className="flex">
                        <div className="flex items-center h-5">
                            <input id="spoiler-checkbox" name="is_spoiler" aria-describedby="spoiler-checkbox-text"
                                   type="checkbox" value=""
                                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                   checked={isSpoiler}
                                   onChange={() => setIsSpoiler(!isSpoiler)}
                            />
                        </div>
                        <div className="ms-2 text-sm">
                            <label htmlFor="spoiler-checkbox"
                                   className="font-medium text-gray-900 dark:text-gray-300">Spoiler?</label>
                            <p id="spoiler-checkbox-text"
                               className="text-xs font-normal text-gray-500 dark:text-gray-300">If checked marks this
                                content as a potential spoiler.</p>
                        </div>
                    </div>
                </div>
                <GameSelect
                    onGameSelect={setSelectedGame}
                    selectedGame={selectedGame}
                />
                <div>
                    <div className="flex">
                        <div className="flex items-center h-5">
                            <input id="private-checkbox" name="is_private" aria-describedby="private-checkbox-text"
                                   type="checkbox" value=""
                                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                   checked={isPrivate}
                                   onChange={() => setIsPrivate(!isPrivate)}
                            />
                        </div>
                        <div className="ms-2 text-sm">
                            <label htmlFor="private-checkbox" className="font-medium text-gray-900 dark:text-gray-300">Private
                                video</label>
                            <p id="private-checkbox-text"
                               className="text-xs font-normal text-gray-500 dark:text-gray-300">If checked this content
                                will
                                not show up in user feeds.</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
);

UploadEditForm.displayName = "UploadEditForm";

export default UploadEditForm;
