import * as React from "react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid";
import {Combobox} from "@headlessui/react";
import debounce from "lodash.debounce";
import type {Game} from "../../types";
import classNames from "classnames";

interface Props {
    selectedGame: Game | null;
    onGameSelect: (game: Game | null) => void;
    showLabel?: boolean;
    showUpDownIcon?: boolean;
    labelClassName?: string;
    inputClassName?: string;
}

export default function GameSelect({
                                       selectedGame,
                                       onGameSelect,
                                       showLabel = true,
                                       showUpDownIcon = true,
                                       labelClassName = "text-sm font-medium text-white",
                                       inputClassName = "bg-gray-600 text-gray-400 shadow-sm ring-1 ring-inset ring-slate-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                   }: Props) {
    const [games, setGames] = React.useState<Array<Game>>([]);
    const [query, setQuery] = React.useState<string>(selectedGame?.name || "");
    const [data, setData] = React.useState<Array<Game>>([]);

    React.useEffect(() => {
        if (!query.length) {
            setGames([]);
            onGameSelect(null);
            return;
        }
        fetch('/game-select?search=' + query).then(x => x.json()).then(data => setData(data));
    }, [query]);

    React.useEffect(() => {
        if (data?.length) {
            setGames(data);
        }
    }, [data]);

    const changeHandler = React.useCallback(
        debounce((val: string) => {
            setQuery(val);
        }, 500),
        []
    );

    const filteredGames =
        query === ""
            ? games
            : games.filter((game) => {
                return game.name.toLowerCase().includes(query.toLowerCase());
            });

    return (
        <Combobox
            as="div"
            value={selectedGame}
            onChange={onGameSelect}
            multiple={false}
        >
            {showLabel && (
                <Combobox.Label className={"block " + labelClassName}>
                    Game
                </Combobox.Label>
            )}
            <div className="relative mt-1">
                <Combobox.Input
                    name="igdb_id"
                    className={
                        `w-full rounded-md border  py-2 pl-3 pr-10 shadow-sm  focus:outline-none focus:ring-1 ` +
                        inputClassName
                    }
                    onChange={(event) => changeHandler(event.target.value)}
                    displayValue={(game: Game) => game?.name}
                />
                <Combobox.Button
                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    {showUpDownIcon && (
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    )}
                </Combobox.Button>

                {filteredGames.length > 0 && (
                    <Combobox.Options
                        className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredGames.map((game) => (
                            <Combobox.Option
                                key={game.igdb_id}
                                value={game}
                                className={({active}) =>
                                    classNames(
                                        "relative cursor-default select-none py-2 pl-3 pr-9",
                                        active ? "bg-indigo-600 text-white" : "text-gray-900"
                                    )
                                }
                            >
                                {({active, selected}) => (
                                    <>
                                        <div className="flex items-center">
                                            <img
                                                src={`https:` + game.cover}
                                                alt=""
                                                className="h-6 w-6 flex-shrink-0"
                                            />
                                            <span
                                                className={classNames(
                                                    "ml-3 truncate",
                                                    selected ? "font-semibold" : ""
                                                )}
                                            >
                        {game.name}
                      </span>
                                        </div>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    "absolute inset-y-0 right-0 flex items-center pr-4",
                                                    active ? "text-white" : "text-indigo-600"
                                                )}
                                            >
                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                      </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
}
